@import 'src/variables.scss';
@import 'src/_mixins.scss';

.mat-mdc-menu-panel {
  max-width: 400px !important;
  width: 300px !important;
  max-height: 324px !important;
  overflow: hidden;
  border-radius: 4px;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.mat-mdc-menu-panel::-webkit-scrollbar {
  background: transparent;
}

.mat-mdc-menu-panel::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 50px;
  background-color: #e1e5ea;
  height: 50px;
}

.select-list {
  margin-top: 8px;
}

.width-100px {
  margin-top: 5px;

  &.mat-mdc-menu-panel {
    max-width: 100px !important;
    width: 100px !important;
    max-height: 324px !important;
    overflow: hidden;
    border-radius: 4px;

    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
}