/* You can add global styles to this file, and also import other style files */
@import "variables";
@import "mixins";
@import "material-menus";

$font-path: './assets/fonts/Lexend/static/';
$font-family-lexend: 'Lexend';

@font-face {
  font-family: 'Lexend';
  font-style: 'regular';
  font-weight: 400;
  src: url('./assets/fonts/Lexend/static/Lexend-Regular.ttf') format('truetype');
}

html,
body {
  height: 100%;
  font-family: 'Lexend';
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  color: var(--background-variant-6475258, #475258) !important;

  /* Body/Body (Auto) - Regular - 14|auto */
  font-family: 'Lexend' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: -0.14px !important;

  border-radius: 8px !important;
  border: 1px solid var(--background-secondary-e-1-e-5-ea, #e1e5ea) !important;
  background: var(--background-primary-ffffff, #fff) !important;
  box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.08) !important;
  display: inline-flex !important;
  padding: 15px !important;
  align-items: flex-start !important;
  align-content: flex-start !important;
  gap: 8px !important;
  flex-wrap: wrap !important;
}

.dialog-panel {
  overflow-y: auto;
}

.dialog-panel .mat-dialog-container {
  border-radius: 8px;
  padding: 24px 0px;
  overflow-y: auto;
  height: 'auto';
  box-shadow: none;
}

.dialog-panel .mat-dialog-content {
  padding: 0px;
  margin: 0px;
}

.dialog-panel .mat-dialog-title {
  display: flex;
  position: relative;
  align-items: center;
  flex-flow: column;
}
